.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.container {
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.main-text {
  color: #000000;
  font-weight: bold;
  font-style: italic;
}

.secondary-text {
  color: #808080;
  font-weight: 400;
  font-size: 14px;

}

.label-text {
  color: #808080;
  font-weight: 400;
  font-size: 12px;

}

.delivery-details {
  margin: 0 0 20px 0;
}

.additional-feedback {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: start;
}

textarea {
  border-color: #88694e;
  margin-top: 5px;
}

textarea:focus-visible {
  outline: #88694e auto 1px;
}

.animated-rater {
  transform: translateZ(0);
  cursor: pointer;
}
.animated-rater .react-rater > * {
  padding-left: 1rem;
}
.animated-rater svg {
  cursor: pointer;
}

button {
  display: inline-block;
  padding: 8px 40px;
  border-radius: 25px;
  background-color: #88694e;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
  border: none !important;
  cursor: pointer;
}
